<template>
  <div class="mt-5 px-xl-5">
    <h6 v-if="!showRiskManagement" class="page-title pb-2 text-uppercase">
      <b-button class="main-btn rounded me-2" @click="goToSuperintendence">
        <b-icon icon="arrow-left"></b-icon></b-button>
      {{ $t("navbar.superintendence") }}
    </h6>
    <b-tabs fill nav-class="dash-nav-tab" v-model="tabIndex">
      <b-tab v-if="!showRiskManagement || showSuperintendences" :title="$t('risk-management.title')"
        title-item-class="dash-tab" @click="changeTab(0)">
        <RiskTable />
      </b-tab>
      <b-tab :title="$t('risk-management.risk-report')" title-item-class="dash-tab" @click="changeTab(1)">
        <RiskReport />
      </b-tab>
      <b-tab :title="$t('risk-management.risk-measurement')" title-item-class="dash-tab" @click="changeTab(2)"
        v-if="!showRiskManagement || showSuperintendences">
        <RiskMeasurement />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import RiskTable from "./RiskTable.vue";
import RiskReport from "./RiskReport.vue";
import RiskMeasurement from "./RiskMeasurement.vue";
import { mapGetters } from "vuex";

export default {
  name: "risk-management",
  components: {
    RiskTable,
    RiskReport,
    RiskMeasurement,
  },
  data() {
    return {
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters("application", [
      "allowSuperintendenceRiskManagement",
      "allowSuperintendenceAsignRoles"
    ]),
    showRiskManagement() {
      return this.allowSuperintendenceRiskManagement;
    },
    showSuperintendences() {
      return this.allowSuperintendenceAsignRoles;
    },
  },
  methods: {
    goToSuperintendence() {
      this.$router.push({
        name: "superintendences",
      });
    },
    changeTab(index) {
      this.tabIndex = index;
    },
  },
};
</script>
